import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"

const IndexPage = () => (
  <Layout>
    <SEO title="Support" />
    <Navigation />
    <div>
      <p style={{ paddingLeft: "3rem", paddingTop: "6rem" }}>
        For support, please email{" "}
        <a href="mailto:info@capturednow.com">info@capturednow.com</a>.
      </p>
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
